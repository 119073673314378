<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <h1>TEAM FEEDBACK</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-row class="sc-title mt-7">
      <v-col md="8" sm="6">
        <div>
          <p>Rep = Billy Krohne</p>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="reset = false"
          >
            Add Feedback
          </v-btn>
        </div>
      </template>

      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Add Feedback</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <date-time
                              v-model="addFeedback.date"
                              :date="true"
                              :rules="[(v) => !!v || 'This field is required']"
                              :reset="reset"
                            >
                            </date-time>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-select
                              label="Type"
                              v-model="addFeedback.type"
                              :rules="[(v) => !!v || 'This field is required']"
                              :items="select_from_open_slots.items"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-textarea
                              label="Description / Comments"
                              v-model="addFeedback.comments"
                              :rules="[(v) => !!v || 'This field is required']"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-textarea
                              label="Ideas / Solutions"
                              v-model="addFeedback.ideas"
                              :rules="[(v) => !!v || 'This field is required']"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <datatable
      :headers="headers"
      :staticData="select_from_open_slots.data"
      :componentURL="componentURL"
    ></datatable>
  </v-container>
</template>

<script>
import Datatable from "../../components/Datatable/Datatable";
import DateTime from "../../components/DateTime/DateTime";

export default {
  components: { Datatable, DateTime },
  data() {
    return {
      componentURL: "TeamFeedBack",
      valid: false,
      dialog: false,
      reset: false,
      headers: [
        { text: "Date", value: "feedback_date" },
        { text: "Type", value: "feedback_type" },
        { text: "Description / Comments", value: "comments" },
        { text: "Ideas / Solutions", value: "ideas" },
      ],

      select_from_open_slots: {
        data: [],
        items: [
          "COD",
          "Big Idea",
          "Big Picture",
          "Victory",
          "Shout Out",
          "Conflict",
          "Concern",
          "Competitor",
        ],
      },
      addFeedback: {
        date: "",
        type: "",
        comments: "",
        ideas: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.select_from_open_slots.data.push(this.addFeedback);
        this.dialog = false;
        this.addFeedback = { date: "", type: "", comments: "", ideas: "" };
        this.reset = true;
      }
    },
  },
};
</script>

<style></style>
